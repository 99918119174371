import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <h1>Tada!!!</h1>
    <p>Congrats on making it to the ripe OLD age of 30, thats only 10 off 40!</p>
    <p>Now that you have turned 30, it is about time you experience the fear of potential death, and to experience this, the only safe way is to go dive with some sharks.</p>
    <p>This experience includes 2 nights in Sydney, kid free, where you will dive with the predators which includes sharks at Sydney SEA LIFE. Watch the video below for what you are in for. Think of it as a prep for the real thing when you go see great whites with Calypso Star Charters in SA.</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/5NuHwU9lyVo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		<p><a href="https://www.sydneyaquarium.com.au/tickets/shark-dive-xtreme/">Full details of the Shark Dive Xtreme Experience</a></p>
  </Layout>
)

export default IndexPage
